export const TelescopeData = {
  summary: {
    name: "Seneca-CDOT/telescope",
    description:
      "Telescope is an open source web server and client application for aggregating and presenting a timeline of Seneca's open source blogs.",
    tools: ["JavaScript", "Travis CI"],
    contribution: 1,
    contributionLink:
      "https://github.com/pulls?q=is%3Apr+author%3Abadalsarkar+archived%3Afalse+is%3Aclosed+repo%3ASeneca-CDOT%2Ftelescope",
    detailsLink: "/work-details/opensource/telescope",
  },
  details: {
    about: `Seneca College is active in the open source community. For more than a decade, faculty and students have been blogging
      about their open source research and development using a variety of platforms (WordPress, Medium, Blogger, and many others). In
      order to keep track of all these posts across so many different platforms, Telescope was created.\n
      
      Telescope is an open source web server and client application for aggregating and presenting a timeline of Seneca's 
      open source blogs. Telescope makes it easy to see what's happening with open source at Seneca right now.`,
    tools: ["JavaScript", "Travis CI"],
    currentlyWorking: [],
    completedWork: [
      {
        title: "Remove Travis CI",
        link: "https://github.com/Seneca-CDOT/telescope/pull/1317",
        type: "New Feature",
      },
    ],
    blogs: [
      {
        title: "Fixing Issue in Telescope",
        link:
          "https://badalsarkar.ca/2020/fixing-telescope-issues/2020-11-21-Fixing-Telescope-Issues/",
      },
    ],
  },
}
