export const AmazingShopData = {
    summary: {
        name: "Amazing Shop",
        description: "An E-Commerce application.",
        tools: ["Java", "Spring", "Maven", "JUnit5"],
        detailsLink:"/work-details/projects/amazing-shop"
    },
    details:{
        about:"",
        tools: ["Java", "Spring", "Maven", "JUnit5"],
        blogs:[]
    }
};