export const BlinkData = {
    summary: {
        name: "Blink",
        description: "A CLI application to detect dead URL and check URL in WayBack machine.",
        tools: ["Java", "Maven", "JUnit5", "GitHub Actions", "Jacoco" ],
        detailsLink:"/work-details/projects/blink"
    },
    details:{
        about:"A CLI application to detect dead URL and check URL in WayBack machine.",
        tools:["Java", "Maven", "JUnit5", "GitHub Actions", "Jacoco" ],
        blogs:[
            {
              title: "Releasing Blink to Maven Central",
              link:"https://badalsarkar.ca/2020/release-blink-mavencentral/2020-12-05-releasing-blink-to-the-maven-central/"
            },
            {
                title:"Integrating Continuous Integration with GitHub Actions",
                link:"https://badalsarkar.ca/2020/testing-ci-workflow/2020-11-27-testing-and-ci-workflow-blink/"
            },
            {
                title:"Using Git branch to maintain Blink development history",
                link:"https://badalsarkar.ca/2020/working-with-git-branch/"
            },
            {
                title:"Refactoring Blink to improve modularity.",
                link:"https://badalsarkar.ca/2020/refactoring-blink/2020-10-23-Refactoring/"
            },
            {
                title:"Integrating Blink with Telescope, a blog aggregation website. ",
                link:"https://badalsarkar.ca/2020/working-wit-telescope/2020-11-6-Working-with-telescope/"
            },
            {
                title:"Blink gets static analysis tools",
                link:"https://badalsarkar.ca/2020/blink-static-analysis/2020-11-13-Blink-gets-static-analysis-tools/"
            },
            {
                title:"Blink- A command line tool to find broken URL",
                link:"https://badalsarkar.ca/2020/blink/"
            },
        ]
    }
}


