export const AidManagementData = {
    summary: {
        name: "Aid Management Application",
        description: "A console application that helps manage Aid distribution.",
        tools: ["C++", "GDB"],
        detailsLink:"/work-details/projects/aid-management-app"
    },
    details:{
        about:"",
        tools: ["C++", "GDB"],
        blogs:[]
    }
};